var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "v-container",
        { staticClass: "px-6 py-6", attrs: { fluid: "" } },
        [
          _c(
            "v-row",
            { staticClass: "mt-1" },
            [
              _c(
                "v-col",
                { attrs: { lg: "7", cols: "12" } },
                [
                  _c(
                    "v-card",
                    {
                      staticClass:
                        "border-radius-xl card-shadow py-5 mb-lg-0 mb-6",
                    },
                    [
                      _c(
                        "div",
                        { staticClass: "px-4 py-4 mt-n14" },
                        [
                          _c(
                            "v-tabs-items",
                            {
                              staticClass: "shadow-dark border-radius-lg",
                              model: {
                                value: _vm.tab,
                                callback: function ($$v) {
                                  _vm.tab = $$v
                                },
                                expression: "tab",
                              },
                            },
                            [
                              _c(
                                "v-tab-item",
                                { key: "1", attrs: { value: "tab-1" } },
                                [
                                  _c(
                                    "div",
                                    {
                                      staticClass:
                                        "height-400 border-radius-lg",
                                      style:
                                        "background-image: url(" +
                                        require("../../assets/img/bg-smart-home-1.jpg") +
                                        "); background-size: cover;",
                                    },
                                    [
                                      _c(
                                        "div",
                                        {
                                          staticClass:
                                            "position-absolute d-flex top-0 w-100",
                                        },
                                        [
                                          _c(
                                            "p",
                                            {
                                              staticClass:
                                                "text-white px-4 py-4 mb-0",
                                            },
                                            [_vm._v("17.05.2021 4:34PM")]
                                          ),
                                          _c(
                                            "div",
                                            {
                                              staticClass: "ms-auto px-4 py-4",
                                            },
                                            [
                                              _c(
                                                "v-badge",
                                                {
                                                  staticClass:
                                                    "\n                          badge badge-secondary\n                          bg-light\n                          px-2\n                          py-2\n                          font-weight-bold\n                          text-uppercase text-xs\n                          border-radius-md\n                        ",
                                                },
                                                [
                                                  _c("i", {
                                                    staticClass:
                                                      "fas fa-dot-circle text-danger",
                                                    attrs: {
                                                      "aria-hidden": "true",
                                                    },
                                                  }),
                                                  _vm._v(" Recording "),
                                                ]
                                              ),
                                            ],
                                            1
                                          ),
                                        ]
                                      ),
                                    ]
                                  ),
                                ]
                              ),
                              _c(
                                "v-tab-item",
                                { key: "2", attrs: { value: "tab-2" } },
                                [
                                  _c(
                                    "div",
                                    {
                                      staticClass:
                                        "height-400 border-radius-lg",
                                      style:
                                        "background-image: url(" +
                                        require("../../assets/img/bg-smart-home-2.jpg") +
                                        "); background-size: cover;",
                                    },
                                    [
                                      _c(
                                        "div",
                                        {
                                          staticClass:
                                            "position-absolute d-flex top-0 w-100",
                                        },
                                        [
                                          _c(
                                            "p",
                                            {
                                              staticClass:
                                                "text-white px-4 py-4 mb-0",
                                            },
                                            [_vm._v("17.05.2021 4:35PM")]
                                          ),
                                          _c(
                                            "div",
                                            {
                                              staticClass: "ms-auto px-4 py-4",
                                            },
                                            [
                                              _c(
                                                "v-badge",
                                                {
                                                  staticClass:
                                                    "\n                          badge badge-secondary\n                          bg-light\n                          px-2\n                          py-2\n                          font-weight-bold\n                          text-uppercase text-xs\n                          border-radius-md\n                        ",
                                                },
                                                [
                                                  _c("i", {
                                                    staticClass:
                                                      "fas fa-dot-circle text-danger",
                                                    attrs: {
                                                      "aria-hidden": "true",
                                                    },
                                                  }),
                                                  _vm._v(" Recording "),
                                                ]
                                              ),
                                            ],
                                            1
                                          ),
                                        ]
                                      ),
                                    ]
                                  ),
                                ]
                              ),
                              _c(
                                "v-tab-item",
                                { key: "3", attrs: { value: "tab-3" } },
                                [
                                  _c(
                                    "div",
                                    {
                                      staticClass:
                                        "height-400 border-radius-lg",
                                      style:
                                        "background-image: url(" +
                                        require("../../assets/img/home-decor-3.jpg") +
                                        "); background-size: cover;",
                                    },
                                    [
                                      _c(
                                        "div",
                                        {
                                          staticClass:
                                            "position-absolute d-flex top-0 w-100",
                                        },
                                        [
                                          _c(
                                            "p",
                                            {
                                              staticClass:
                                                "text-white px-4 py-4 mb-0",
                                            },
                                            [_vm._v("17.05.2021 4:57PM")]
                                          ),
                                          _c(
                                            "div",
                                            {
                                              staticClass: "ms-auto px-4 py-4",
                                            },
                                            [
                                              _c(
                                                "v-badge",
                                                {
                                                  staticClass:
                                                    "\n                          badge badge-secondary\n                          bg-light\n                          px-2\n                          py-2\n                          font-weight-bold\n                          text-uppercase text-xs\n                          border-radius-md\n                        ",
                                                },
                                                [
                                                  _c("i", {
                                                    staticClass:
                                                      "fas fa-dot-circle text-danger",
                                                    attrs: {
                                                      "aria-hidden": "true",
                                                    },
                                                  }),
                                                  _vm._v(" Recording "),
                                                ]
                                              ),
                                            ],
                                            1
                                          ),
                                        ]
                                      ),
                                    ]
                                  ),
                                ]
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "div",
                        {
                          staticClass:
                            "d-flex pb-0 px-4 pb-0 align-items-center",
                        },
                        [
                          _c(
                            "h6",
                            {
                              staticClass:
                                "text-h6 my-auto text-typo font-weight-bold me-5",
                            },
                            [_vm._v(" Cameras ")]
                          ),
                          _c(
                            "v-tabs",
                            {
                              staticClass: "overflow-hidden",
                              attrs: { right: "" },
                              model: {
                                value: _vm.tab,
                                callback: function ($$v) {
                                  _vm.tab = $$v
                                },
                                expression: "tab",
                              },
                            },
                            [
                              _c("v-tabs-slider"),
                              _c("v-tab", { attrs: { href: "#tab-1" } }, [
                                _vm._v(" Kitchen "),
                              ]),
                              _c("v-tab", { attrs: { href: "#tab-2" } }, [
                                _vm._v(" Living "),
                              ]),
                              _c("v-tab", { attrs: { href: "#tab-3" } }, [
                                _vm._v(" Attic "),
                              ]),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ]
                  ),
                ],
                1
              ),
              _c(
                "v-col",
                { attrs: { lg: "5", cols: "12" } },
                [
                  _c(
                    "v-row",
                    [
                      _c(
                        "v-col",
                        { attrs: { cols: "12" } },
                        [
                          _c(
                            "v-card",
                            {
                              staticClass:
                                "card-shadow border-radius-xl px-4 pt-5 h-100",
                            },
                            [
                              _c(
                                "div",
                                {
                                  staticClass:
                                    "\n                  px-6\n                  py-4\n                  bg-gradient-primary\n                  shadow-primary\n                  border-radius-lg\n                  mt-n10\n                  mb-6\n                ",
                                },
                                [
                                  _c(
                                    "v-row",
                                    [
                                      _c(
                                        "v-col",
                                        {
                                          staticClass: "my-auto",
                                          attrs: { cols: "8" },
                                        },
                                        [
                                          _c(
                                            "div",
                                            { staticClass: "numbers" },
                                            [
                                              _c(
                                                "p",
                                                {
                                                  staticClass:
                                                    "\n                          text-white text-sm\n                          mb-0\n                          text-capitalize\n                          font-weight-bold\n                          opacity-7\n                        ",
                                                },
                                                [_vm._v(" Weather today ")]
                                              ),
                                              _c(
                                                "h5",
                                                {
                                                  staticClass:
                                                    "text-white text-h5 font-weight-bolder mb-0",
                                                },
                                                [
                                                  _vm._v(
                                                    " San Francisco - 29°C "
                                                  ),
                                                ]
                                              ),
                                            ]
                                          ),
                                        ]
                                      ),
                                      _c(
                                        "v-col",
                                        {
                                          staticClass: "text-end",
                                          attrs: { cols: "4" },
                                        },
                                        [
                                          _c("v-img", {
                                            staticClass: "w-50 ms-auto",
                                            attrs: {
                                              src: require("@/assets/img/small-logos/icon-sun-cloud.png"),
                                            },
                                          }),
                                          _c(
                                            "h5",
                                            {
                                              staticClass:
                                                "mb-0 text-white text-h5 text-end me-1",
                                            },
                                            [_vm._v(" Sunny ")]
                                          ),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                              _c("div", { staticClass: "chart-area my-6" }, [
                                _c("canvas", {
                                  attrs: { height: 320, id: "chart-weather" },
                                }),
                              ]),
                            ]
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "v-row",
            [
              _c(
                "v-col",
                { attrs: { md: "3", cols: "12" } },
                [
                  _c(
                    "v-card",
                    { staticClass: "border-radius-xl card-shadow" },
                    [
                      _c("div", { staticClass: "px-6 py-6 text-center" }, [
                        _c(
                          "h1",
                          {
                            staticClass:
                              "text-gradient text-primary font-weight-bolder text-h1",
                          },
                          [
                            _c("span", [_vm._v("21")]),
                            _c("span", { staticClass: "text-lg" }, [
                              _vm._v("°C"),
                            ]),
                          ]
                        ),
                        _c(
                          "h6",
                          {
                            staticClass:
                              "mb-0 text-h6 font-weight-bolder text-typo",
                          },
                          [_vm._v(" Living Room ")]
                        ),
                        _c(
                          "p",
                          { staticClass: "opacity-8 mb-0 text-sm text-typo" },
                          [_vm._v("Temperature")]
                        ),
                      ]),
                    ]
                  ),
                ],
                1
              ),
              _c(
                "v-col",
                { attrs: { md: "3", cols: "12" } },
                [
                  _c(
                    "v-card",
                    { staticClass: "border-radius-xl card-shadow" },
                    [
                      _c("div", { staticClass: "px-6 py-6 text-center" }, [
                        _c(
                          "h1",
                          {
                            staticClass:
                              "text-gradient text-primary font-weight-bolder text-h1",
                          },
                          [
                            _c("span", [_vm._v("44")]),
                            _c("span", { staticClass: "text-lg ms-1" }, [
                              _vm._v("%"),
                            ]),
                          ]
                        ),
                        _c(
                          "h6",
                          {
                            staticClass:
                              "mb-0 text-h6 font-weight-bolder text-typo",
                          },
                          [_vm._v("Outside")]
                        ),
                        _c(
                          "p",
                          { staticClass: "opacity-8 mb-0 text-sm text-typo" },
                          [_vm._v("Humidity")]
                        ),
                      ]),
                    ]
                  ),
                ],
                1
              ),
              _c(
                "v-col",
                { attrs: { md: "3", cols: "12" } },
                [
                  _c(
                    "v-card",
                    { staticClass: "border-radius-xl card-shadow" },
                    [
                      _c("div", { staticClass: "px-6 py-6 text-center" }, [
                        _c(
                          "h1",
                          {
                            staticClass:
                              "text-gradient text-primary font-weight-bolder text-h1",
                          },
                          [
                            _c("span", [_vm._v("87")]),
                            _c("span", { staticClass: "text-lg" }, [
                              _vm._v("m³"),
                            ]),
                          ]
                        ),
                        _c(
                          "h6",
                          {
                            staticClass:
                              "mb-0 text-h6 font-weight-bolder text-typo",
                          },
                          [_vm._v("Water")]
                        ),
                        _c(
                          "p",
                          { staticClass: "opacity-8 mb-0 text-sm text-typo" },
                          [_vm._v("Consumption")]
                        ),
                      ]),
                    ]
                  ),
                ],
                1
              ),
              _c(
                "v-col",
                { attrs: { md: "3", cols: "12" } },
                [
                  _c(
                    "v-card",
                    { staticClass: "border-radius-xl card-shadow" },
                    [
                      _c("div", { staticClass: "px-6 py-6 text-center" }, [
                        _c(
                          "h1",
                          {
                            staticClass:
                              "text-gradient text-primary font-weight-bolder text-h1",
                          },
                          [
                            _c("span", [_vm._v("417")]),
                            _c("span", { staticClass: "text-lg ms-1" }, [
                              _vm._v("GB"),
                            ]),
                          ]
                        ),
                        _c(
                          "h6",
                          {
                            staticClass:
                              "mb-0 text-h6 font-weight-bolder text-typo",
                          },
                          [_vm._v(" Internet ")]
                        ),
                        _c(
                          "p",
                          { staticClass: "opacity-8 mb-0 text-sm text-typo" },
                          [_vm._v("All devices")]
                        ),
                      ]),
                    ]
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "v-row",
            [
              _c(
                "v-col",
                { attrs: { lg: "6" } },
                [
                  _c(
                    "v-card",
                    { staticClass: "card-shadow border-radius-xl h-100" },
                    [
                      _c("div", { staticClass: "px-4 py-4" }, [
                        _c(
                          "h6",
                          { staticClass: "text-h6 text-typo font-weight-bold" },
                          [_vm._v("Device limit")]
                        ),
                        _c(
                          "div",
                          { staticClass: "text-start mt-4" },
                          [
                            _c(
                              "v-row",
                              [
                                _c(
                                  "v-col",
                                  {
                                    staticClass: "text-center",
                                    attrs: { lg: "6" },
                                  },
                                  [
                                    _c("round-slider", {
                                      staticClass:
                                        "d-flex justify-center mx-auto",
                                      attrs: {
                                        min: "16",
                                        max: "38",
                                        width: "6",
                                        borderColor: "#d3d3d3",
                                        pathColor: "#d3d3d3",
                                        rangeColor: "#cb0c9f",
                                        tooltipColor: "#344767",
                                        handleSize: "20",
                                        "start-angle": "315",
                                        "end-angle": "+270",
                                        "line-cap": "round",
                                        radius: "80",
                                      },
                                      model: {
                                        value: _vm.sliderValue,
                                        callback: function ($$v) {
                                          _vm.sliderValue = $$v
                                        },
                                        expression: "sliderValue",
                                      },
                                    }),
                                    _c(
                                      "p",
                                      {
                                        staticClass: "ps-1 mt-2 mb-0 text-body",
                                      },
                                      [
                                        _c("span", { staticClass: "text-xs" }, [
                                          _vm._v("16°C"),
                                        ]),
                                        _c("span", { staticClass: "px-3" }, [
                                          _vm._v("Temperature"),
                                        ]),
                                        _c("span", { staticClass: "text-xs" }, [
                                          _vm._v("38°C"),
                                        ]),
                                      ]
                                    ),
                                  ],
                                  1
                                ),
                                _c(
                                  "v-col",
                                  {
                                    staticClass: "my-auto",
                                    attrs: { lg: "6" },
                                  },
                                  [
                                    _c("v-simple-table", {
                                      staticClass: "table",
                                      scopedSlots: _vm._u([
                                        {
                                          key: "default",
                                          fn: function () {
                                            return [
                                              _c("tbody", [
                                                _c("tr", [
                                                  _c("td", [
                                                    _c(
                                                      "div",
                                                      {
                                                        staticClass:
                                                          "d-flex px-2 py-0",
                                                      },
                                                      [
                                                        _c(
                                                          "div",
                                                          {
                                                            staticClass:
                                                              "\n                                  d-flex\n                                  flex-column\n                                  justify-content-center\n                                ",
                                                          },
                                                          [
                                                            _c(
                                                              "h6",
                                                              {
                                                                staticClass:
                                                                  "\n                                    mb-0\n                                    text-sm text-typo\n                                    font-weight-bold\n                                  ",
                                                              },
                                                              [
                                                                _vm._v(
                                                                  " Current Temperature "
                                                                ),
                                                              ]
                                                            ),
                                                          ]
                                                        ),
                                                      ]
                                                    ),
                                                  ]),
                                                  _c(
                                                    "td",
                                                    {
                                                      staticClass:
                                                        "align-middle text-center text-sm",
                                                    },
                                                    [
                                                      _c(
                                                        "span",
                                                        {
                                                          staticClass:
                                                            "text-xs font-weight-light text-body",
                                                        },
                                                        [_vm._v(" 21°C ")]
                                                      ),
                                                    ]
                                                  ),
                                                ]),
                                                _c("tr", [
                                                  _c("td", [
                                                    _c(
                                                      "div",
                                                      {
                                                        staticClass:
                                                          "d-flex px-2 py-0",
                                                      },
                                                      [
                                                        _c(
                                                          "div",
                                                          {
                                                            staticClass:
                                                              "\n                                  d-flex\n                                  flex-column\n                                  justify-content-center\n                                ",
                                                          },
                                                          [
                                                            _c(
                                                              "h6",
                                                              {
                                                                staticClass:
                                                                  "\n                                    mb-0\n                                    text-sm text-typo\n                                    font-weight-bold\n                                  ",
                                                              },
                                                              [
                                                                _vm._v(
                                                                  " Humidity "
                                                                ),
                                                              ]
                                                            ),
                                                          ]
                                                        ),
                                                      ]
                                                    ),
                                                  ]),
                                                  _c(
                                                    "td",
                                                    {
                                                      staticClass:
                                                        "align-middle text-center text-sm",
                                                    },
                                                    [
                                                      _c(
                                                        "span",
                                                        {
                                                          staticClass:
                                                            "text-xs font-weight-light text-body",
                                                        },
                                                        [_vm._v(" 57% ")]
                                                      ),
                                                    ]
                                                  ),
                                                ]),
                                              ]),
                                            ]
                                          },
                                          proxy: true,
                                        },
                                      ]),
                                    }),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                      ]),
                    ]
                  ),
                ],
                1
              ),
              _c(
                "v-col",
                { attrs: { lg: "6" } },
                [
                  _c(
                    "v-card",
                    { staticClass: "card-shadow border-radius-xl" },
                    [
                      _c("div", { staticClass: "pb-0 px-4 pt-4" }, [
                        _c(
                          "div",
                          { staticClass: "d-flex align-items-center" },
                          [
                            _c(
                              "h6",
                              {
                                staticClass:
                                  "text-h6 text-typo mb-0 font-weight-bold",
                              },
                              [_vm._v(" Consumption by room ")]
                            ),
                            _c(
                              "v-tooltip",
                              {
                                attrs: { top: "" },
                                scopedSlots: _vm._u([
                                  {
                                    key: "activator",
                                    fn: function (ref) {
                                      var on = ref.on
                                      var attrs = ref.attrs
                                      return [
                                        _c(
                                          "v-btn",
                                          _vm._g(
                                            _vm._b(
                                              {
                                                staticClass:
                                                  "\n                      font-weight-600\n                      text-capitalize\n                      btn-outline-secondary\n                      py-3\n                      px-4\n                      ms-auto\n                    ",
                                                attrs: {
                                                  small: "",
                                                  rounded: "",
                                                  "min-width": "25",
                                                  elevation: "0",
                                                  ripple: false,
                                                  height: "25",
                                                  width: "25",
                                                  color: "transparent",
                                                },
                                              },
                                              "v-btn",
                                              attrs,
                                              false
                                            ),
                                            on
                                          ),
                                          [
                                            _c(
                                              "v-icon",
                                              { attrs: { size: "8" } },
                                              [_vm._v("fas fa-info")]
                                            ),
                                          ],
                                          1
                                        ),
                                      ]
                                    },
                                  },
                                ]),
                              },
                              [
                                _c("span", [
                                  _vm._v("See the consumption per room"),
                                ]),
                              ]
                            ),
                          ],
                          1
                        ),
                      ]),
                      _c(
                        "div",
                        { staticClass: "px-4 py-4" },
                        [
                          _c(
                            "v-row",
                            [
                              _c(
                                "v-col",
                                {
                                  staticClass: "text-center position-relative",
                                  attrs: { cols: "5" },
                                },
                                [
                                  _c("div", { staticClass: "chart" }, [
                                    _c("canvas", {
                                      staticClass: "chart-canvas",
                                      attrs: {
                                        id: "chart-consumption",
                                        height: "197",
                                      },
                                    }),
                                  ]),
                                  _c(
                                    "div",
                                    {
                                      staticClass:
                                        "mt-n16 ms-n3 position-absolute w-100",
                                    },
                                    [
                                      _c(
                                        "h4",
                                        {
                                          staticClass:
                                            "font-weight-bold text-h4 text-typo mt-n16",
                                        },
                                        [
                                          _c("span", [_vm._v("471.3")]),
                                          _c(
                                            "span",
                                            {
                                              staticClass:
                                                "d-block text-body text-sm",
                                            },
                                            [_vm._v("WATTS")]
                                          ),
                                        ]
                                      ),
                                    ]
                                  ),
                                ]
                              ),
                              _c(
                                "v-col",
                                { attrs: { cols: "7" } },
                                [
                                  _c("v-simple-table", {
                                    staticClass: "table",
                                    attrs: { height: "200px" },
                                    scopedSlots: _vm._u([
                                      {
                                        key: "default",
                                        fn: function () {
                                          return [
                                            _c("tbody", [
                                              _c("tr", [
                                                _c("td", [
                                                  _c(
                                                    "div",
                                                    {
                                                      staticClass:
                                                        "d-flex px-2 py-0",
                                                    },
                                                    [
                                                      _c("span", {
                                                        staticClass:
                                                          "\n                                px-3\n                                py-2\n                                border-radius-md\n                                bg-gradient-primary\n                                me-4\n                              ",
                                                      }),
                                                      _c(
                                                        "div",
                                                        {
                                                          staticClass:
                                                            "\n                                d-flex\n                                flex-column\n                                justify-content-center\n                              ",
                                                        },
                                                        [
                                                          _c(
                                                            "h6",
                                                            {
                                                              staticClass:
                                                                "\n                                  mb-0\n                                  text-sm text-typo\n                                  font-weight-bold\n                                ",
                                                            },
                                                            [
                                                              _vm._v(
                                                                " Living Room "
                                                              ),
                                                            ]
                                                          ),
                                                        ]
                                                      ),
                                                    ]
                                                  ),
                                                ]),
                                                _c(
                                                  "td",
                                                  {
                                                    staticClass:
                                                      "align-middle text-center text-sm",
                                                  },
                                                  [
                                                    _c(
                                                      "span",
                                                      {
                                                        staticClass:
                                                          "text-xs font-weight-bold text-body",
                                                      },
                                                      [_vm._v(" 15% ")]
                                                    ),
                                                  ]
                                                ),
                                              ]),
                                              _c("tr", [
                                                _c("td", [
                                                  _c(
                                                    "div",
                                                    {
                                                      staticClass:
                                                        "d-flex px-2 py-0",
                                                    },
                                                    [
                                                      _c("span", {
                                                        staticClass:
                                                          "\n                                px-3\n                                py-2\n                                border-radius-md\n                                badge\n                                bg-gradient-secondary\n                                me-4\n                              ",
                                                      }),
                                                      _c(
                                                        "div",
                                                        {
                                                          staticClass:
                                                            "\n                                d-flex\n                                flex-column\n                                justify-content-center\n                              ",
                                                        },
                                                        [
                                                          _c(
                                                            "h6",
                                                            {
                                                              staticClass:
                                                                "\n                                  mb-0\n                                  text-sm text-typo\n                                  font-weight-bold\n                                ",
                                                            },
                                                            [
                                                              _vm._v(
                                                                " Kitchen "
                                                              ),
                                                            ]
                                                          ),
                                                        ]
                                                      ),
                                                    ]
                                                  ),
                                                ]),
                                                _c(
                                                  "td",
                                                  {
                                                    staticClass:
                                                      "align-middle text-center text-sm",
                                                  },
                                                  [
                                                    _c(
                                                      "span",
                                                      {
                                                        staticClass:
                                                          "text-xs font-weight-bold text-body",
                                                      },
                                                      [_vm._v(" 20% ")]
                                                    ),
                                                  ]
                                                ),
                                              ]),
                                              _c("tr", [
                                                _c("td", [
                                                  _c(
                                                    "div",
                                                    {
                                                      staticClass:
                                                        "d-flex px-2 py-0",
                                                    },
                                                    [
                                                      _c("span", {
                                                        staticClass:
                                                          "\n                                px-3\n                                py-2\n                                border-radius-md\n                                badge\n                                bg-gradient-info\n                                me-3\n                              ",
                                                      }),
                                                      _c(
                                                        "div",
                                                        {
                                                          staticClass:
                                                            "\n                                d-flex\n                                flex-column\n                                justify-content-center\n                              ",
                                                        },
                                                        [
                                                          _c(
                                                            "h6",
                                                            {
                                                              staticClass:
                                                                "\n                                  mb-0\n                                  text-sm text-typo\n                                  font-weight-bold\n                                ",
                                                            },
                                                            [_vm._v(" Attic ")]
                                                          ),
                                                        ]
                                                      ),
                                                    ]
                                                  ),
                                                ]),
                                                _c(
                                                  "td",
                                                  {
                                                    staticClass:
                                                      "align-middle text-center text-sm",
                                                  },
                                                  [
                                                    _c(
                                                      "span",
                                                      {
                                                        staticClass:
                                                          "text-xs font-weight-bold text-body",
                                                      },
                                                      [_vm._v(" 13% ")]
                                                    ),
                                                  ]
                                                ),
                                              ]),
                                              _c("tr", [
                                                _c("td", [
                                                  _c(
                                                    "div",
                                                    {
                                                      staticClass:
                                                        "d-flex px-2 py-0",
                                                    },
                                                    [
                                                      _c("span", {
                                                        staticClass:
                                                          "\n                                px-3\n                                py-2\n                                border-radius-md\n                                badge\n                                bg-gradient-primary\n                                me-3\n                              ",
                                                      }),
                                                      _c(
                                                        "div",
                                                        {
                                                          staticClass:
                                                            "\n                                d-flex\n                                flex-column\n                                justify-content-center\n                              ",
                                                        },
                                                        [
                                                          _c(
                                                            "h6",
                                                            {
                                                              staticClass:
                                                                "\n                                  mb-0\n                                  text-sm text-typo\n                                  font-weight-bold\n                                ",
                                                            },
                                                            [_vm._v(" Garage ")]
                                                          ),
                                                        ]
                                                      ),
                                                    ]
                                                  ),
                                                ]),
                                                _c(
                                                  "td",
                                                  {
                                                    staticClass:
                                                      "align-middle text-center text-sm",
                                                  },
                                                  [
                                                    _c(
                                                      "span",
                                                      {
                                                        staticClass:
                                                          "text-xs font-weight-bold text-body",
                                                      },
                                                      [_vm._v(" 32% ")]
                                                    ),
                                                  ]
                                                ),
                                              ]),
                                              _c("tr", [
                                                _c("td", [
                                                  _c(
                                                    "div",
                                                    {
                                                      staticClass:
                                                        "d-flex px-2 py-0",
                                                    },
                                                    [
                                                      _c("span", {
                                                        staticClass:
                                                          "\n                                px-3\n                                py-2\n                                border-radius-md\n                                badge\n                                bg-gradient-warning\n                                me-3\n                              ",
                                                      }),
                                                      _c(
                                                        "div",
                                                        {
                                                          staticClass:
                                                            "\n                                d-flex\n                                flex-column\n                                justify-content-center\n                              ",
                                                        },
                                                        [
                                                          _c(
                                                            "h6",
                                                            {
                                                              staticClass:
                                                                "\n                                  mb-0\n                                  text-sm text-typo\n                                  font-weight-bold\n                                ",
                                                            },
                                                            [
                                                              _vm._v(
                                                                " Basement "
                                                              ),
                                                            ]
                                                          ),
                                                        ]
                                                      ),
                                                    ]
                                                  ),
                                                ]),
                                                _c(
                                                  "td",
                                                  {
                                                    staticClass:
                                                      "align-middle text-center text-sm",
                                                  },
                                                  [
                                                    _c(
                                                      "span",
                                                      {
                                                        staticClass:
                                                          "text-xs font-weight-bold text-body",
                                                      },
                                                      [_vm._v(" 20% ")]
                                                    ),
                                                  ]
                                                ),
                                              ]),
                                            ]),
                                          ]
                                        },
                                        proxy: true,
                                      },
                                    ]),
                                  }),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ]
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c("hr", { staticClass: "horizontal dark my-12" }),
          _c(
            "v-row",
            [
              _c(
                "v-col",
                { attrs: { lg: "2" } },
                [
                  _c(
                    "v-card",
                    {
                      staticClass:
                        "px-4 py-4 card-shadow border-radius-xl h-100",
                    },
                    [
                      _c(
                        "div",
                        { staticClass: "d-flex mb-4" },
                        [
                          _c(
                            "p",
                            { staticClass: "mb-0 text-body font-weight-light" },
                            [_vm._v("Off")]
                          ),
                          _c("v-switch", {
                            staticClass:
                              "d-inline-flex mt-0 pt-0 switch ms-auto",
                            attrs: { ripple: false, "hide-details": "" },
                            model: {
                              value: _vm.switche,
                              callback: function ($$v) {
                                _vm.switche = $$v
                              },
                              expression: "switche",
                            },
                          }),
                        ],
                        1
                      ),
                      _c(
                        "svg",
                        {
                          attrs: {
                            width: "60",
                            viewBox: "0 0 296 179",
                            fill: "none",
                            xmlns: "http://www.w3.org/2000/svg",
                          },
                        },
                        [
                          _c("g", { attrs: { filter: "url(#filter1_f)" } }, [
                            _c("path", {
                              attrs: {
                                d: "M165.907 34.625C165.907 34.625 156.143 47.861 148.512 47.425C138.946 46.863 137.312 35.325 128.444 34.625C119.166 34.764 118.219 46.725 108.163 47.425C99.1529 47.264 95.3359 34.843 86.7469 34.625C78.1579 34.407 69.0029 47.425 69.0029 47.425",
                                stroke: "#CED4DA",
                                "stroke-width": "16",
                                "stroke-linecap": "round",
                              },
                            }),
                            _c("path", {
                              attrs: {
                                d: "M154.919 102.625C154.919 102.625 140.219 115.861 128.726 115.425C114.326 114.863 111.855 103.325 98.508 102.625C84.538 102.764 83.108 114.725 67.969 115.425C54.403 115.262 48.655 102.842 35.719 102.625C22.783 102.408 9 115.425 9 115.425",
                                stroke: "#CED4DA",
                                "stroke-width": "16",
                                "stroke-linecap": "round",
                              },
                            }),
                            _c("path", {
                              attrs: {
                                d: "M238.919 156.625C238.919 156.625 224.219 169.861 212.726 169.425C198.326 168.863 195.855 157.325 182.508 156.625C168.538 156.764 167.108 168.725 151.969 169.425C138.403 169.262 132.655 156.842 119.719 156.625C106.783 156.408 93 169.425 93 169.425",
                                stroke: "#CED4DA",
                                "stroke-width": "16",
                                "stroke-linecap": "round",
                              },
                            }),
                            _c("path", {
                              attrs: {
                                d: "M264.076 1.00001C261.378 1.03054 234 43.7744 234 60.3879C234 68.3648 237.169 76.015 242.809 81.6555C248.45 87.296 256.1 90.4648 264.077 90.4648C272.054 90.4648 279.704 87.296 285.344 81.6555C290.985 76.015 294.154 68.3648 294.154 60.3879C294.154 43.7744 266.775 0.970878 264.076 1.00001Z",
                                fill: "#CED4DA",
                              },
                            }),
                            _c("path", {
                              attrs: {
                                d: "M282.441 49.6635C279.436 50.5823 278.383 55.8514 280.089 61.4324C281.796 67.0134 285.615 70.7928 288.62 69.874C291.625 68.9553 292.678 63.6862 290.972 58.1052C289.266 52.5242 285.446 48.7448 282.441 49.6635Z",
                                fill: "white",
                              },
                            }),
                            _c("path", {
                              attrs: {
                                d: "M207.411 61C205.49 61.0222 186 91.4522 186 103.278C186 108.956 188.256 114.402 192.271 118.418C196.287 122.433 201.733 124.689 207.411 124.689C213.09 124.689 218.536 122.433 222.552 118.418C226.567 114.402 228.823 108.956 228.823 103.278C228.823 91.4522 209.332 60.9792 207.411 61Z",
                                fill: "#CED4DA",
                              },
                            }),
                            _c("path", {
                              attrs: {
                                d: "M219.337 96.8934C217.197 97.5476 216.447 101.299 217.662 105.272C218.877 109.245 221.596 111.936 223.736 111.282C225.876 110.627 226.626 106.876 225.411 102.903C224.196 98.9298 221.477 96.2392 219.337 96.8934Z",
                                fill: "white",
                              },
                            }),
                          ]),
                          _c("defs", [
                            _c(
                              "filter",
                              {
                                attrs: {
                                  id: "filter1_f",
                                  x: "0",
                                  y: "0",
                                  width: "295.154",
                                  height: "178.435",
                                  filterUnits: "userSpaceOnUse",
                                  "color-interpolation-filters": "sRGB",
                                },
                              },
                              [
                                _c("feFlood", {
                                  attrs: {
                                    "flood-opacity": "0",
                                    result: "BackgroundImageFix",
                                  },
                                }),
                                _c("feBlend", {
                                  attrs: {
                                    mode: "normal",
                                    in: "SourceGraphic",
                                    in2: "BackgroundImageFix",
                                    result: "shape",
                                  },
                                }),
                                _c("feGaussianBlur", {
                                  attrs: {
                                    stdDeviation: "0.5",
                                    result: "effect1_foregroundBlur",
                                  },
                                }),
                              ],
                              1
                            ),
                          ]),
                        ]
                      ),
                      _c(
                        "p",
                        { staticClass: "mt-4 mb-0 text-body font-weight-bold" },
                        [_vm._v("Humidity")]
                      ),
                      _c("span", { staticClass: "text-xs text-body" }, [
                        _vm._v("Inactive since: 2 days"),
                      ]),
                    ]
                  ),
                ],
                1
              ),
              _c(
                "v-col",
                { attrs: { lg: "2" } },
                [
                  _c(
                    "v-card",
                    {
                      staticClass:
                        "\n            px-4\n            py-4\n            card-shadow\n            border-radius-xl\n            bg-gradient-primary\n            h-100\n          ",
                    },
                    [
                      _c(
                        "div",
                        { staticClass: "d-flex mb-4" },
                        [
                          _c(
                            "p",
                            {
                              staticClass: "mb-0 text-white font-weight-light",
                            },
                            [_vm._v("On")]
                          ),
                          _c("v-switch", {
                            staticClass:
                              "d-inline-flex mt-0 pt-0 switch ms-auto",
                            attrs: { ripple: false, "hide-details": "" },
                            model: {
                              value: _vm.switch1,
                              callback: function ($$v) {
                                _vm.switch1 = $$v
                              },
                              expression: "switch1",
                            },
                          }),
                        ],
                        1
                      ),
                      _c(
                        "svg",
                        {
                          attrs: {
                            width: "40",
                            viewBox: "0 0 217 342",
                            fill: "none",
                            xmlns: "http://www.w3.org/2000/svg",
                          },
                        },
                        [
                          _c("g", { attrs: { filter: "url(#filter3_f)" } }, [
                            _c("path", {
                              attrs: {
                                d: "M67 178.583C93.5097 178.583 115 157.092 115 130.583C115 104.073 93.5097 82.5825 67 82.5825C40.4903 82.5825 19 104.073 19 130.583C19 157.092 40.4903 178.583 67 178.583Z",
                                fill: "white",
                              },
                            }),
                            _c("path", {
                              attrs: {
                                d: "M67 188.583C99.0325 188.583 125 162.615 125 130.583C125 98.55 99.0325 72.5825 67 72.5825C34.9675 72.5825 9 98.55 9 130.583C9 162.615 34.9675 188.583 67 188.583Z",
                                stroke: "white",
                                "stroke-width": "10",
                                "stroke-linecap": "round",
                                "stroke-dasharray": "1 66",
                              },
                            }),
                            _c("g", { attrs: { filter: "url(#filter3_f)" } }, [
                              _c("path", {
                                attrs: {
                                  d: "M61.6224 120.764C78.6362 111.727 88.2605 96.5543 83.1189 86.8741C77.9773 77.1939 60.0169 76.6723 43.0031 85.7091C25.9894 94.7458 16.3651 109.919 21.5067 119.599C26.6482 129.279 44.6087 129.801 61.6224 120.764Z",
                                  fill: "white",
                                },
                              }),
                            ]),
                          ]),
                          _c("g", { attrs: { filter: "url(#filter2_d)" } }, [
                            _c("path", {
                              attrs: {
                                d: "M83.768 199.054L83.768 63.0005C83.768 53.7179 87.4555 44.8155 94.0192 38.2518C100.583 31.688 109.485 28.0005 118.768 28.0005C128.051 28.0005 136.953 31.688 143.517 38.2518C150.08 44.8155 153.768 53.7179 153.768 63.0005L153.768 201.97C162.845 209.089 169.677 218.673 173.448 229.574C177.219 240.475 177.768 252.232 175.03 263.438C172.291 274.643 166.382 284.822 158.008 292.755C149.634 300.689 139.152 306.041 127.815 308.17C123.7 309.262 119.422 309.601 115.187 309.17C102.415 308.838 90.0831 304.437 79.9858 296.609C69.8886 288.781 62.5533 277.935 59.0477 265.649C55.5422 253.364 56.0495 240.28 60.4957 228.302C64.9419 216.325 73.095 206.079 83.768 199.056V199.054Z",
                                fill: "url(#paint0_linear)",
                              },
                            }),
                            _c("path", {
                              attrs: {
                                d: "M57.7443 249.189C57.7443 257.029 59.257 264.644 62.2406 271.821C65.1226 278.754 69.2556 285.005 74.5247 290.402C77.1201 293.061 79.9789 295.485 83.0218 297.609C86.0911 299.75 89.3762 301.605 92.7861 303.122C99.8678 306.273 107.414 307.971 115.213 308.169L115.251 308.17L115.289 308.174C116.437 308.291 117.607 308.35 118.769 308.35C121.749 308.35 124.707 307.964 127.558 307.203L127.595 307.193L127.632 307.186C134.372 305.929 140.788 303.521 146.701 300.029C152.492 296.61 157.625 292.258 161.958 287.096C166.323 281.896 169.728 276.046 172.078 269.71C174.511 263.153 175.745 256.248 175.745 249.189C175.745 244.564 175.208 239.958 174.149 235.501C173.12 231.169 171.592 226.949 169.607 222.957C167.658 219.036 165.262 215.323 162.485 211.919C159.725 208.536 156.585 205.453 153.151 202.757L152.769 202.457V201.97L152.769 63.0001C152.769 58.4096 151.87 53.957 150.097 49.766C148.385 45.7174 145.933 42.0811 142.81 38.9583C139.688 35.8355 136.051 33.3839 132.003 31.6715C127.812 29.8988 123.359 29 118.769 29C114.178 29 109.726 29.8988 105.535 31.6715C101.486 33.3839 97.8497 35.8355 94.7269 38.9583C91.6041 42.0811 89.1524 45.7174 87.44 49.766C85.6674 53.957 84.7686 58.4096 84.7686 63.0001L84.7686 199.055V199.593L84.3189 199.89C76.3153 205.164 69.6443 212.384 65.0268 220.769C62.6796 225.031 60.8688 229.575 59.6449 234.275C58.3838 239.117 57.7443 244.135 57.7443 249.189ZM56.7443 249.189C56.7443 228.231 67.4872 209.785 83.7686 199.055L83.7686 63.0001C83.7686 43.6699 99.4385 28 118.769 28C138.099 28 153.769 43.6699 153.769 63.0001L153.769 201.97C167.757 212.955 176.745 230.023 176.745 249.189C176.745 278.544 155.665 302.975 127.816 308.169C124.93 308.94 121.898 309.35 118.769 309.35C117.559 309.35 116.366 309.289 115.188 309.169C82.7694 308.344 56.7443 281.806 56.7443 249.189Z",
                                fill: "white",
                                "fill-opacity": "0.5",
                              },
                            }),
                          ]),
                          _c("path", {
                            attrs: {
                              d: "M72.6179 250.554C72.616 242.091 74.9489 233.792 79.3597 226.569C83.7705 219.347 90.0883 213.481 97.6179 209.617V85.5544H139.618V209.616C146.888 213.347 153.032 218.946 157.421 225.838C161.809 232.73 164.282 240.667 164.586 248.832C164.89 256.998 163.014 265.096 159.15 272.296C155.286 279.495 149.574 285.536 142.602 289.796C135.63 294.056 127.648 296.382 119.479 296.535C111.31 296.688 103.247 294.662 96.1202 290.666C88.9933 286.67 83.0595 280.848 78.9288 273.798C74.7982 266.748 72.6199 258.725 72.6179 250.554Z",
                              fill: "white",
                            },
                          }),
                          _c(
                            "defs",
                            [
                              _c(
                                "filter",
                                {
                                  attrs: {
                                    id: "filter30_f",
                                    x: "2",
                                    y: "65.5825",
                                    width: "130",
                                    height: "130",
                                    filterUnits: "userSpaceOnUse",
                                    "color-interpolation-filters": "sRGB",
                                  },
                                },
                                [
                                  _c("feFlood", {
                                    attrs: {
                                      "flood-opacity": "0",
                                      result: "BackgroundImageFix",
                                    },
                                  }),
                                  _c("feBlend", {
                                    attrs: {
                                      mode: "normal",
                                      in: "SourceGraphic",
                                      in2: "BackgroundImageFix",
                                      result: "shape",
                                    },
                                  }),
                                  _c("feGaussianBlur", {
                                    attrs: {
                                      stdDeviation: "1",
                                      result: "effect1_foregroundBlur",
                                    },
                                  }),
                                ],
                                1
                              ),
                              _c(
                                "filter",
                                {
                                  attrs: {
                                    id: "filter30_f",
                                    x: "0.122314",
                                    y: "59.2585",
                                    width: "104.381",
                                    height: "87.9562",
                                    filterUnits: "userSpaceOnUse",
                                    "color-interpolation-filters": "sRGB",
                                  },
                                },
                                [
                                  _c("feFlood", {
                                    attrs: {
                                      "flood-opacity": "0",
                                      result: "BackgroundImageFix",
                                    },
                                  }),
                                  _c("feBlend", {
                                    attrs: {
                                      mode: "normal",
                                      in: "SourceGraphic",
                                      in2: "BackgroundImageFix",
                                      result: "shape",
                                    },
                                  }),
                                  _c("feGaussianBlur", {
                                    attrs: {
                                      stdDeviation: "10",
                                      result: "effect1_foregroundBlur",
                                    },
                                  }),
                                ],
                                1
                              ),
                              _c(
                                "filter",
                                {
                                  attrs: {
                                    id: "filter2_d",
                                    x: "36.7441",
                                    y: "0",
                                    width: "180.001",
                                    height: "341.351",
                                    filterUnits: "userSpaceOnUse",
                                    "color-interpolation-filters": "sRGB",
                                  },
                                },
                                [
                                  _c("feFlood", {
                                    attrs: {
                                      "flood-opacity": "0",
                                      result: "BackgroundImageFix",
                                    },
                                  }),
                                  _c("feColorMatrix", {
                                    attrs: {
                                      in: "SourceAlpha",
                                      type: "matrix",
                                      values:
                                        "0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0",
                                    },
                                  }),
                                  _c("feOffset", {
                                    attrs: { dx: "10", dy: "2" },
                                  }),
                                  _c("feGaussianBlur", {
                                    attrs: { stdDeviation: "15" },
                                  }),
                                  _c("feColorMatrix", {
                                    attrs: {
                                      type: "matrix",
                                      values:
                                        "0 0 0 0 0.501961 0 0 0 0 0.501961 0 0 0 0 0.501961 0 0 0 0.302 0",
                                    },
                                  }),
                                  _c("feBlend", {
                                    attrs: {
                                      mode: "normal",
                                      in2: "BackgroundImageFix",
                                      result: "effect1_dropShadow",
                                    },
                                  }),
                                  _c("feBlend", {
                                    attrs: {
                                      mode: "normal",
                                      in: "SourceGraphic",
                                      in2: "effect1_dropShadow",
                                      result: "shape",
                                    },
                                  }),
                                ],
                                1
                              ),
                              _c(
                                "linearGradient",
                                {
                                  attrs: {
                                    id: "paint0_linear",
                                    x1: "161.625",
                                    y1: "141.948",
                                    x2: "43.5768",
                                    y2: "169.912",
                                    gradientUnits: "userSpaceOnUse",
                                  },
                                },
                                [
                                  _c("stop", {
                                    attrs: {
                                      offset: "1",
                                      "stop-color": "white",
                                      "stop-opacity": "0.596",
                                    },
                                  }),
                                  _c("stop", {
                                    attrs: {
                                      offset: "1",
                                      "stop-color": "#F7F7F7",
                                      "stop-opacity": "0.204",
                                    },
                                  }),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ]
                      ),
                      _c(
                        "p",
                        {
                          staticClass: "mt-4 mb-0 text-white font-weight-bold",
                        },
                        [_vm._v("Temperature")]
                      ),
                      _c("span", { staticClass: "text-xs text-white" }, [
                        _vm._v("Active"),
                      ]),
                    ]
                  ),
                ],
                1
              ),
              _c(
                "v-col",
                { attrs: { lg: "2" } },
                [
                  _c(
                    "v-card",
                    {
                      staticClass:
                        "px-4 py-4 card-shadow border-radius-xl h-100",
                    },
                    [
                      _c(
                        "div",
                        { staticClass: "d-flex mb-4" },
                        [
                          _c(
                            "p",
                            { staticClass: "mb-0 text-body font-weight-light" },
                            [_vm._v("Off")]
                          ),
                          _c("v-switch", {
                            staticClass:
                              "d-inline-flex mt-0 pt-0 switch ms-auto",
                            attrs: { ripple: false, "hide-details": "" },
                            model: {
                              value: _vm.switch3,
                              callback: function ($$v) {
                                _vm.switch3 = $$v
                              },
                              expression: "switch3",
                            },
                          }),
                        ],
                        1
                      ),
                      _c(
                        "svg",
                        {
                          attrs: {
                            width: "70",
                            viewBox: "0 0 306 180",
                            fill: "none",
                            xmlns: "http://www.w3.org/2000/svg",
                          },
                        },
                        [
                          _c("g", { attrs: { filter: "url(#filter0_f)" } }, [
                            _c("path", {
                              attrs: {
                                d: "M165.907 35.625C165.907 35.625 156.143 48.861 148.512 48.425C138.946 47.863 137.312 36.325 128.444 35.625C119.166 35.764 118.219 47.725 108.163 48.425C99.1529 48.264 95.3359 35.843 86.7469 35.625C78.1579 35.407 69.0029 48.425 69.0029 48.425",
                                stroke: "#CED4DA",
                                "stroke-width": "16",
                                "stroke-linecap": "round",
                              },
                            }),
                            _c("path", {
                              attrs: {
                                d: "M154.919 103.625C154.919 103.625 140.219 116.861 128.726 116.425C114.326 115.863 111.855 104.325 98.508 103.625C84.538 103.764 83.108 115.725 67.969 116.425C54.403 116.262 48.655 103.842 35.719 103.625C22.783 103.408 9 116.425 9 116.425",
                                stroke: "#CED4DA",
                                "stroke-width": "16",
                                "stroke-linecap": "round",
                              },
                            }),
                            _c("path", {
                              attrs: {
                                d: "M238.919 157.625C238.919 157.625 224.219 170.861 212.726 170.425C198.326 169.863 195.855 158.325 182.508 157.625C168.538 157.764 167.108 169.725 151.969 170.425C138.403 170.262 132.655 157.842 119.719 157.625C106.783 157.408 93 170.425 93 170.425",
                                stroke: "#CED4DA",
                                "stroke-width": "16",
                                "stroke-linecap": "round",
                              },
                            }),
                            _c("path", {
                              attrs: {
                                d: "M220.25 19.4441L257.266 111.061",
                                stroke: "#CED4DA",
                                "stroke-width": "10",
                                "stroke-linecap": "round",
                              },
                            }),
                            _c("path", {
                              attrs: {
                                d: "M229.482 41.7656L207.122 31.8528",
                                stroke: "#CED4DA",
                                "stroke-width": "10",
                                "stroke-linecap": "round",
                              },
                            }),
                            _c("path", {
                              attrs: {
                                d: "M229.482 41.7655L239.189 18.897",
                                stroke: "#CED4DA",
                                "stroke-width": "10",
                                "stroke-linecap": "round",
                              },
                            }),
                            _c("path", {
                              attrs: {
                                d: "M249.223 90.6272L240.025 113.29",
                                stroke: "#CED4DA",
                                "stroke-width": "10",
                                "stroke-linecap": "round",
                              },
                            }),
                            _c("path", {
                              attrs: {
                                d: "M249.223 90.6271L272.091 100.334",
                                stroke: "#CED4DA",
                                "stroke-width": "10",
                                "stroke-linecap": "round",
                              },
                            }),
                            _c("path", {
                              attrs: {
                                d: "M190.205 58.6675L288.055 72.4195",
                                stroke: "#CED4DA",
                                "stroke-width": "10",
                                "stroke-linecap": "round",
                              },
                            }),
                            _c("path", {
                              attrs: {
                                d: "M214.151 61.834L194.387 76.2415",
                                stroke: "#CED4DA",
                                "stroke-width": "10",
                                "stroke-linecap": "round",
                              },
                            }),
                            _c("path", {
                              attrs: {
                                d: "M214.151 61.834L199.2 41.9931",
                                stroke: "#CED4DA",
                                "stroke-width": "10",
                                "stroke-linecap": "round",
                              },
                            }),
                            _c("path", {
                              attrs: {
                                d: "M266.338 69.1682L281.365 88.4654",
                                stroke: "#CED4DA",
                                "stroke-width": "10",
                                "stroke-linecap": "round",
                              },
                            }),
                            _c("path", {
                              attrs: {
                                d: "M266.338 69.1682L286.179 54.217",
                                stroke: "#CED4DA",
                                "stroke-width": "10",
                                "stroke-linecap": "round",
                              },
                            }),
                            _c("path", {
                              attrs: {
                                d: "M209.151 104.299L269.986 26.4342",
                                stroke: "#CED4DA",
                                "stroke-width": "10",
                                "stroke-linecap": "round",
                              },
                            }),
                            _c("path", {
                              attrs: {
                                d: "M223.866 85.1443L226.461 109.465",
                                stroke: "#CED4DA",
                                "stroke-width": "10",
                                "stroke-linecap": "round",
                              },
                            }),
                            _c("path", {
                              attrs: {
                                d: "M223.866 85.1442L199.208 88.1718",
                                stroke: "#CED4DA",
                                "stroke-width": "10",
                                "stroke-linecap": "round",
                              },
                            }),
                            _c("path", {
                              attrs: {
                                d: "M256.311 43.6169L280.536 40.2513",
                                stroke: "#CED4DA",
                                "stroke-width": "10",
                                "stroke-linecap": "round",
                              },
                            }),
                            _c("path", {
                              attrs: {
                                d: "M256.311 43.6168L253.283 18.9585",
                                stroke: "#CED4DA",
                                "stroke-width": "10",
                                "stroke-linecap": "round",
                              },
                            }),
                            _c("circle", {
                              attrs: {
                                cx: "239.321",
                                cy: "66.5",
                                r: "8.5",
                                fill: "white",
                              },
                            }),
                          ]),
                          _c("defs", [
                            _c(
                              "filter",
                              {
                                attrs: {
                                  id: "filter0_f",
                                  x: "0",
                                  y: "10.9402",
                                  width: "294.703",
                                  height: "168.495",
                                  filterUnits: "userSpaceOnUse",
                                  "color-interpolation-filters": "sRGB",
                                },
                              },
                              [
                                _c("feFlood", {
                                  attrs: {
                                    "flood-opacity": "0",
                                    result: "BackgroundImageFix",
                                  },
                                }),
                                _c("feBlend", {
                                  attrs: {
                                    mode: "normal",
                                    in: "SourceGraphic",
                                    in2: "BackgroundImageFix",
                                    result: "shape",
                                  },
                                }),
                                _c("feGaussianBlur", {
                                  attrs: {
                                    stdDeviation: "0.5",
                                    result: "effect1_foregroundBlur",
                                  },
                                }),
                              ],
                              1
                            ),
                          ]),
                        ]
                      ),
                      _c(
                        "p",
                        { staticClass: "mt-4 mb-0 text-body font-weight-bold" },
                        [_vm._v("Air Conditioner")]
                      ),
                      _c("span", { staticClass: "text-xs text-body" }, [
                        _vm._v("Inactive since: 1 hour"),
                      ]),
                    ]
                  ),
                ],
                1
              ),
              _c(
                "v-col",
                { attrs: { lg: "2" } },
                [
                  _c(
                    "v-card",
                    {
                      staticClass:
                        "px-4 py-4 card-shadow border-radius-xl h-100",
                    },
                    [
                      _c(
                        "div",
                        { staticClass: "d-flex mb-4" },
                        [
                          _c(
                            "p",
                            { staticClass: "mb-0 text-body font-weight-light" },
                            [_vm._v("Off")]
                          ),
                          _c("v-switch", {
                            staticClass:
                              "d-inline-flex mt-0 pt-0 switch ms-auto",
                            attrs: { ripple: false, "hide-details": "" },
                            model: {
                              value: _vm.switch4,
                              callback: function ($$v) {
                                _vm.switch4 = $$v
                              },
                              expression: "switch4",
                            },
                          }),
                        ],
                        1
                      ),
                      _c(
                        "svg",
                        {
                          attrs: {
                            width: "72",
                            viewBox: "0 0 301 157",
                            fill: "none",
                            xmlns: "http://www.w3.org/2000/svg",
                          },
                        },
                        [
                          _c("g", { attrs: { filter: "url(#filter0_f)" } }, [
                            _c(
                              "mask",
                              {
                                attrs: {
                                  "mask-type": "alpha",
                                  maskUnits: "userSpaceOnUse",
                                  x: "0",
                                  y: "0",
                                  width: "301",
                                  height: "156",
                                },
                              },
                              [
                                _c("rect", {
                                  attrs: {
                                    width: "301",
                                    height: "156",
                                    fill: "#C4C4C4",
                                  },
                                }),
                              ]
                            ),
                            _c("g", { attrs: { mask: "url(#mask0)" } }, [
                              _c("path", {
                                attrs: {
                                  d: "M150 250.825C209.094 250.825 257 202.92 257 143.825C257 84.7307 209.094 36.8252 150 36.8252C90.9055 36.8252 43 84.7307 43 143.825C43 202.92 90.9055 250.825 150 250.825Z",
                                  fill: "#CED4DA",
                                },
                              }),
                              _c("path", {
                                attrs: {
                                  d: "M150 277.825C224.006 277.825 284 217.831 284 143.825C284 69.819 224.006 9.8252 150 9.8252C75.9938 9.8252 16 69.819 16 143.825C16 217.831 75.9938 277.825 150 277.825Z",
                                  stroke: "#CED4DA",
                                  "stroke-width": "10",
                                  "stroke-linecap": "round",
                                  "stroke-dasharray": "1 66",
                                },
                              }),
                              _c(
                                "g",
                                { attrs: { filter: "url(#filter1_f)" } },
                                [
                                  _c("path", {
                                    attrs: {
                                      d: "M135.743 126.588C166.447 110.28 183.244 81.824 173.262 63.0298C163.28 44.2355 130.297 42.22 99.5936 58.528C68.8901 74.8359 52.0924 103.292 62.0748 122.086C72.0573 140.88 105.04 142.896 135.743 126.588Z",
                                      fill: "white",
                                    },
                                  }),
                                ]
                              ),
                              _c("path", {
                                attrs: {
                                  d: "M182 143.435H122V156.435H182V143.435Z",
                                  fill: "white",
                                },
                              }),
                              _c("path", {
                                attrs: {
                                  d: "M6.5 151.935H124.063L151.927 133.325L180.5 151.935H294.5",
                                  stroke: "#CED4DA",
                                  "stroke-width": "10",
                                  "stroke-linecap": "round",
                                },
                              }),
                            ]),
                          ]),
                          _c("defs", [
                            _c(
                              "filter",
                              {
                                attrs: {
                                  id: "filter0_f",
                                  x: "0.5",
                                  y: "3.8252",
                                  width: "300",
                                  height: "153.175",
                                  filterUnits: "userSpaceOnUse",
                                  "color-interpolation-filters": "sRGB",
                                },
                              },
                              [
                                _c("feFlood", {
                                  attrs: {
                                    "flood-opacity": "0",
                                    result: "BackgroundImageFix",
                                  },
                                }),
                                _c("feBlend", {
                                  attrs: {
                                    mode: "normal",
                                    in: "SourceGraphic",
                                    in2: "BackgroundImageFix",
                                    result: "shape",
                                  },
                                }),
                                _c("feGaussianBlur", {
                                  attrs: {
                                    stdDeviation: "0.5",
                                    result: "effect1_foregroundBlur",
                                  },
                                }),
                              ],
                              1
                            ),
                            _c(
                              "filter",
                              {
                                attrs: {
                                  id: "filter1_f",
                                  x: "24",
                                  y: "9",
                                  width: "187.337",
                                  height: "167.116",
                                  filterUnits: "userSpaceOnUse",
                                  "color-interpolation-filters": "sRGB",
                                },
                              },
                              [
                                _c("feFlood", {
                                  attrs: {
                                    "flood-opacity": "0",
                                    result: "BackgroundImageFix",
                                  },
                                }),
                                _c("feBlend", {
                                  attrs: {
                                    mode: "normal",
                                    in: "SourceGraphic",
                                    in2: "BackgroundImageFix",
                                    result: "shape",
                                  },
                                }),
                                _c("feGaussianBlur", {
                                  attrs: {
                                    stdDeviation: "10",
                                    result: "effect1_foregroundBlur",
                                  },
                                }),
                              ],
                              1
                            ),
                          ]),
                        ]
                      ),
                      _c(
                        "p",
                        { staticClass: "mt-4 mb-0 text-body font-weight-bold" },
                        [_vm._v("Lights")]
                      ),
                      _c("span", { staticClass: "text-xs text-body" }, [
                        _vm._v("Inactive since: 27 min"),
                      ]),
                    ]
                  ),
                ],
                1
              ),
              _c(
                "v-col",
                { attrs: { lg: "2" } },
                [
                  _c(
                    "v-card",
                    {
                      staticClass:
                        "\n            px-4\n            py-4\n            card-shadow\n            border-radius-xl\n            bg-gradient-primary\n            h-100\n          ",
                    },
                    [
                      _c(
                        "div",
                        { staticClass: "d-flex mb-4" },
                        [
                          _c(
                            "p",
                            {
                              staticClass: "mb-0 text-white font-weight-light",
                            },
                            [_vm._v("On")]
                          ),
                          _c("v-switch", {
                            staticClass:
                              "d-inline-flex mt-0 pt-0 switch ms-auto",
                            attrs: { ripple: false, "hide-details": "" },
                            model: {
                              value: _vm.switch5,
                              callback: function ($$v) {
                                _vm.switch5 = $$v
                              },
                              expression: "switch5",
                            },
                          }),
                        ],
                        1
                      ),
                      _c(
                        "svg",
                        {
                          attrs: {
                            width: "45px",
                            viewBox: "0 0 41 31",
                            version: "1.1",
                            xmlns: "http://www.w3.org/2000/svg",
                            "xmlns:xlink": "http://www.w3.org/1999/xlink",
                          },
                        },
                        [
                          _c("title", [_vm._v("wifi")]),
                          _c(
                            "g",
                            {
                              attrs: {
                                id: "Page-1",
                                stroke: "none",
                                "stroke-width": "1",
                                fill: "none",
                                "fill-rule": "evenodd",
                              },
                            },
                            [
                              _c(
                                "g",
                                {
                                  attrs: {
                                    id: "wifi",
                                    transform: "translate(3.000000, 3.000000)",
                                  },
                                },
                                [
                                  _c("path", {
                                    attrs: {
                                      d: "M7.37102658,14.6156105 C12.9664408,9.02476091 22.0335592,9.02476091 27.6289734,14.6156105",
                                      stroke: "#FFFFFF",
                                      "stroke-width": "5",
                                      "stroke-linecap": "round",
                                      "stroke-linejoin": "round",
                                    },
                                  }),
                                  _c("circle", {
                                    attrs: {
                                      id: "Oval",
                                      fill: "#FFFFFF",
                                      cx: "17.5039082",
                                      cy: "22.7484921",
                                      r: "4.9082855",
                                    },
                                  }),
                                  _c("path", {
                                    attrs: {
                                      d: "M0,7.24718945 C9.66583791,-2.41572982 25.3341621,-2.41572982 35,7.24718945",
                                      stroke: "#FFFFFF",
                                      "stroke-width": "5",
                                      opacity: "0.398982558",
                                      "stroke-linecap": "round",
                                      "stroke-linejoin": "round",
                                    },
                                  }),
                                ]
                              ),
                            ]
                          ),
                        ]
                      ),
                      _c(
                        "p",
                        {
                          staticClass: "mt-4 mb-0 text-white font-weight-bold",
                        },
                        [_vm._v("Wi-fi")]
                      ),
                      _c("span", { staticClass: "text-xs text-white" }, [
                        _vm._v("Active"),
                      ]),
                    ]
                  ),
                ],
                1
              ),
              _c(
                "v-col",
                { attrs: { lg: "2" } },
                [
                  _c(
                    "v-card",
                    {
                      staticClass:
                        "px-4 py-4 card-shadow border-radius-xl h-100",
                    },
                    [
                      _c(
                        "div",
                        {
                          staticClass:
                            "d-flex flex-column justify-center text-center h-100",
                        },
                        [
                          _c(
                            "a",
                            {
                              staticClass: "text-decoration-none",
                              attrs: { href: "javascript:;" },
                            },
                            [
                              _c("i", {
                                staticClass: "fa fa-plus text-secondary mb-3",
                                attrs: { "aria-hidden": "true" },
                              }),
                              _c(
                                "h5",
                                { staticClass: "text-h5 text-secondary" },
                                [_vm._v("New device")]
                              ),
                            ]
                          ),
                        ]
                      ),
                    ]
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }